import React, { useState } from 'react'
import { useForm, ValidationError } from '@formspree/react'
import { PatternFormat } from 'react-number-format'
import * as styles from "./styles.module.css"
import Button from '../button'

const GroupScreeningForm = ({ formEndpoint }) => {
    const [state, handleSubmit] = useForm(`${formEndpoint}`)
    const [other, setOther] = useState("")
    const [otherSelected, setOtherSelected] = useState(false)
    const [formState, setFormState] = useState({
        a_name: "",
        b_street: "",
        c_city: "",
        d_state: "",
        e_postal_code: "",
        _replyto: "",
        f_phone: "",
        g_reference: "",
        h_child_name: "",
        i_birthday: "",
        j_gender: "",
        k_school: "",
        l_grade: "",
        m_individual_therapy: "",
        n_primary_concern: "",
        o_safety_concerns: "",
        p_additional_info: ""
    })
    const handleChange = (event) => {
        const target = event.target
        const name = target.name
        const value = target.type === 'checkbox' ? (target.checked || target.value === "other" ? target.value : "") : target.value
        setFormState({
            ...formState,
            [name]: value,
        })
    }
    const handleOtherChange = (event) => {
        if(event.target.id === "other-radio" || event.target.id === "other") {
            setOtherSelected(true)
        } else {
            setOtherSelected(false)
        }
    }
    // state of submit button
    // enable submit button only if all fields are filled out
    const isEnabled = (
        formState["a_name"].length > 0 &&
        formState["b_street"].length > 0 &&
        formState["c_city"].length > 0 &&
        formState["d_state"].length > 0 &&
        formState["e_postal_code"].length > 0 &&
        formState["_replyto"].length > 0 &&
        formState["f_phone"].length > 0 &&
        formState["g_reference"].length > 0 &&
        formState["h_child_name"].length > 0 &&
        formState["i_birthday"].length > 0 &&
        formState["j_gender"].length > 0 &&
        formState["k_school"].length > 0 &&
        formState["l_grade"].length > 0 &&
        formState["m_individual_therapy"].length > 0 &&
        formState["n_primary_concern"].length > 0 &&
        formState["o_safety_concerns"].length > 0 &&
        formState["p_additional_info"].length > 0
    )
    if (state.succeeded) {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className={styles.success_message}>
                            <h4>Thank you! We'll be in touch with next steps.</h4>
                            <Button type="jumboPurple" url="/request-appointment" text="Return to Form" newTab={false} />
                        </div>
                    </div>
                </div>
            </div>
        ) 
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-7 offset-lg-3">
                    <div className={styles.custom_form}>
                        <form onSubmit={handleSubmit} onChange={handleChange}>
                            <div>
                                <label htmlFor="full-name">What is your name?*</label>
                                <input type="text" name="a_name" id="full-name" placeholder="Your full name" required={true}/>
                                <ValidationError 
                                prefix="Name" 
                                field="a_name"
                                errors={state.errors}
                                />
                            </div>
                            <fieldset>
                                <legend>What is your home address?*</legend>
                                <div>
                                    <label htmlFor="street-name" className="visuallyhidden">Street Name</label>
                                    <input type="text" name="b_street" id="street-name" placeholder="Street name" required={true}/>
                                    <ValidationError 
                                    prefix="Street Name" 
                                    field="b_street"
                                    errors={state.errors}
                                    />
                                </div>
                                <div className={styles.locale}>
                                    <div>
                                        <label htmlFor="city" className="visuallyhidden">City</label>
                                        <input type="text" name="c_city" id="city" placeholder="City" required={true}/>
                                        <ValidationError 
                                        prefix="City" 
                                        field="c_city"
                                        errors={state.errors}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="state" className="visuallyhidden">State</label>
                                        <select defaultValue="" name="d_state" id="state" required={true}>
                                            <option value="" disabled={true}>State</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District Of Columbia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                        <ValidationError 
                                        prefix="State" 
                                        field="d_state"
                                        errors={state.errors}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="postal-code" className="visuallyhidden">Postal Code</label>
                                        <input type="text" name="e_postal_code" id="postal-code" placeholder="Postal code" required={true}/>
                                        <ValidationError 
                                        prefix="Postal Code" 
                                        field="e_postal_code"
                                        errors={state.errors}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <div>
                                <label htmlFor="email-address">What is your email address?*</label>
                                <input type="email" name="_replyto" id="email-address" placeholder="youremail@emailcarrier.com" required={true}/>
                                <ValidationError 
                                prefix="Email" 
                                field="_replyto"
                                errors={state.errors}
                                />
                            </div>
                            <div>
                                <label htmlFor="phone">What is your phone number?*</label>
                                <PatternFormat
                                    format="+1 (###) ###-####" 
                                    mask="_"
                                    required
                                    placeholder="+1 (555) 555-5555"
                                    name="f_phone"
                                    id="phone"
                                />
                                <ValidationError 
                                prefix="Phone" 
                                field="phone"
                                errors={state.errors}
                                />
                            </div>
                            <fieldset onChange={e => handleOtherChange(e)} className={`${styles.radio} ${styles.two_col}`} required={true}>
                                <legend>How did you hear about us?*</legend>
                                <label htmlFor="pediatrician">
                                    <input type="radio" id="pediatrician" name="g_reference" value="Pediatrician"/>Pediatrician
                                </label>
                                <label htmlFor="psychiatrist-psychologist">
                                    <input type="radio" id="psychiatrist-psychologist" name="g_reference" value="Psychiatrist/Psychologist"/>Psychiatrist/Psychologist
                                </label>
                                <label htmlFor="school">
                                    <input type="radio" id="school" name="g_reference" value="School"/>School
                                </label>
                                <label htmlFor="web-search">
                                    <input type="radio" id="web-search" name="g_reference" value="Web Search"/>Web Search
                                </label>
                                <label htmlFor="social-media">
                                    <input type="radio" id="social-media" name="g_reference" value="Social Media"/>Social Media
                                </label>
                                <label htmlFor="email-newsletter">
                                    <input type="radio" id="email-newsletter" name="g_reference" value="Email Newsletter"/>Email Newsletter
                                </label>
                                <label className={styles.other_radio} htmlFor="other-radio">
                                    <input type="radio" id="other-radio" name="g_reference" value="Other"/>{otherSelected ? "" : "Other"}
                                    {otherSelected ? 
                                        <label className={`${styles.other} ${styles.other_text}`} htmlFor="other">
                                            <input id="other" type="text" name="g_reference" placeholder="Other"/>
                                            <span className="visuallyhidden">Other</span>
                                        </label>
                                        : 
                                        ""
                                    }
                                </label>
                                <ValidationError 
                                prefix="Referral" 
                                field="g_reference"
                                errors={state.errors}
                                />
                            </fieldset>
                            <div>
                                <label htmlFor="child-name">What is your child's name?*</label>
                                <input type="text" name="h_child_name" id="child-name" placeholder="First and last name" required={true}/>
                                <ValidationError 
                                prefix="Child's Name" 
                                field="h_child_name"
                                errors={state.errors}
                                />
                            </div>
                            <div>
                                <label htmlFor="birthday">What is your child's date of birth?*</label>
                                <input type="date" id="birthday" name="i_birthday" required={true}/>
                                <ValidationError 
                                prefix="Child's DOB" 
                                field="i_birthday"
                                errors={state.errors}
                                />
                            </div>
                            <fieldset className={`${styles.radio} ${styles.one_col}`} required={true}>
                                <legend>What is your child's assigned gender?*</legend>
                                <label htmlFor="male"><input type="radio" id="male" name="j_gender" value="Male"/>Male</label>
                                <label htmlFor="female"><input type="radio" id="female" name="j_gender" value="Female"/>Female</label>
                                <ValidationError 
                                prefix="Child's Gender" 
                                field="j_gender"
                                errors={state.errors}
                                />
                            </fieldset>
                            <div>
                                <label htmlFor="school">Where does your child attend school?*</label>
                                <input type="text" name="k_school" id="school" placeholder="Name of school" required={true}/>
                                <ValidationError 
                                prefix="School" 
                                field="k_school"
                                errors={state.errors}
                                />
                            </div>
                            <div>
                                <label htmlFor="grade">What grade is your child currently in?*</label>
                                <input type="text" name="l_grade" id="grade" placeholder="Grade level" required={true}/>
                                <ValidationError 
                                prefix="Grade Level" 
                                field="l_grade"
                                errors={state.errors}
                                />
                            </div>
                            <fieldset className={`${styles.radio} ${styles.one_col}`} required={true}>
                                <legend>Is your child currently in individual therapy?*</legend>
                                <label htmlFor="yes"><input type="radio" id="yes" name="m_individual_therapy" value="Yes"/>Yes</label>
                                <label htmlFor="no"><input type="radio" id="no" name="m_individual_therapy" value="No"/>No</label>
                                <ValidationError 
                                prefix="Individual Therapy" 
                                field="m_individual_therapy"
                                errors={state.errors}
                                />
                            </fieldset>
                            <fieldset className={`${styles.radio} ${styles.two_col}`} required={true}>
                                <legend>What is your primary concern? Select all that apply.*</legend>

                                <label htmlFor="parenting-issues">
                                    <input id="parenting-issues" type="checkbox" name="n_primary_concern" value="Parenting Issues"/>
                                    Parenting Issues
                                </label>  

                                <label htmlFor="anxiety">
                                    <input id="anxiety" type="checkbox" name="n_primary_concern" value="Anxiety"/>
                                    Anxiety
                                </label>

                                <label htmlFor="behavioral-difficulties">
                                    <input id="behavioral-difficulties" type="checkbox" name="n_primary_concern" value="Behavioral Difficulties"/>
                                    Behavioral Difficulties
                                </label>

                                <label htmlFor="adhd">
                                    <input id="adhd" type="checkbox" name="n_primary_concern" value="ADHD"/>
                                    ADHD
                                </label>

                                <label htmlFor="ocd">
                                    <input id="ocd" type="checkbox" name="n_primary_concern" value="OCD"/>
                                    OCD
                                </label>

                                <label htmlFor="depression">
                                    <input id="depression" type="checkbox" name="n_primary_concern" value="Depression or Mood Concerns"/>
                                    Depression or Mood Concerns
                                </label>

                                <label htmlFor="trauma">
                                    <input id="trauma" type="checkbox" name="n_primary_concern" value="Trauma"/>
                                    Trauma
                                </label>

                                <label htmlFor="parenting-issues">
                                    <input id="parenting-issues" type="checkbox" name="n_primary_concern" value="Parenting Issues"/>
                                    Parenting Issues
                                </label>

                                <label className={`${other !== "" ? styles.selected : ""} ${styles.other}`} htmlFor="other">
                                    <input onChange={event => setOther(event.target.value)} id="other" type="text" name="n_primary_concern" placeholder="Other"/>
                                    <span className="visuallyhidden">Other</span>
                                </label>

                                <ValidationError 
                                prefix="Primary Concern" 
                                field="n_primary_concern"
                                errors={state.errors}
                                />
                                
                            </fieldset>
                            <fieldset className={`${styles.radio} ${styles.one_col}`} required={true}>
                                <legend>Do you have any safety concerns about your child?*</legend>
                                <label htmlFor="yes"><input type="radio" id="yes" name="o_safety_concerns" value="Yes"/>Yes</label>
                                <label htmlFor="no"><input type="radio" id="no" name="o_safety_concerns" value="No"/>No</label>
                                <ValidationError 
                                prefix="Safety Concerns" 
                                field="o_safety_concerns"
                                errors={state.errors}
                                />
                            </fieldset>
                            <div>
                                <label htmlFor="additional-info">What do you hope to gain from group services?*</label>
                                <input type="text" name="p_additional_info" id="additional-info" placeholder="Type your answer here" required={true}/>
                                <ValidationError 
                                prefix="Additional Info" 
                                field="p_additional_info"
                                errors={state.errors}
                                />
                            </div>
                            <div>
                                <button className={`${state.submitting || !isEnabled ? styles.disabled : ""}`} type="submit" disabled={state.submitting || !isEnabled}>
                                    {state.submitting ? "Submitting..." : "Submit Responses"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroupScreeningForm
