// extracted by mini-css-extract-plugin
export var banner = "styles-module--banner--b4fa4";
export var circle = "styles-module--circle--0d111";
export var colors = "\"../../assets/css/_colors.css\"";
export var deepOrange = "#FF8A0F";
export var deepYellow = "#FFCC23";
export var hide_mobile = "styles-module--hide_mobile--63504";
export var parallelogram = "styles-module--parallelogram--3c081";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var show_mobile = "styles-module--show_mobile--3df01";
export var title = "styles-module--title--da238";
export var triangle = "styles-module--triangle--4b802";
export var yellowBtn = "#FFE07B";